import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { brandsSchema, BrandsType } from "../../../types/brands";
import CarBrandsView from "./CarBrandsView";

export default function CarBrands() {
  const navigate = useNavigate();
  const data = useLoaderData();
  const [search, setSearch] = useState("");

  const brands: BrandsType = brandsSchema.parse(data);
  const filteredBrands = brands.filter((b) =>
    b.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <CarBrandsView
      brands={filteredBrands}
      onClick={() => navigate(-1)}
      onChange={(text) => setSearch(text)}
    />
  );
}
