import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getInstallations } from "../api/installations";
import { RootState } from "../store/store";
import { Installation, installationArray } from "../types/installation";

type TrackerInstallationState =
  | { tag: "UNLOADED" }
  | { tag: "ERROR" }
  | { tag: "LOADED"; installations: Installation[] };

const initialState: TrackerInstallationState = { tag: "UNLOADED" };

const installationSlice = createSlice({
  name: "installation",
  initialState: initialState as TrackerInstallationState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getInstallationsAction.fulfilled,
      (_, action) => action.payload
    );
  },
});

export const installationReducer = installationSlice.reducer;
export const installationActions = installationSlice.actions;
export const selectInstallations = (state: RootState) => state.installation;

export const getInstallationsAction = createAsyncThunk(
  "installations/get",
  async (): Promise<TrackerInstallationState> => {
    const response = await getInstallations();

    if (!response.ok) return { tag: "ERROR" };

    return {
      tag: "LOADED",
      installations: installationArray.parse(response.value),
    };
  }
);
