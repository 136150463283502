import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";

type TermsOfServiceState = { tag: "NOT_ACCEPTED" } | { tag: "ACCEPTED" };

const initialState: TermsOfServiceState = { tag: "NOT_ACCEPTED" };

const termsOfServiceSlice = createSlice({
  name: "termsOfService",
  initialState: initialState as TermsOfServiceState,
  reducers: {
    acceptTerms: (_) => {
      window.localStorage.setItem(
        "InstallerAcceptedTermsOfService",
        "Zm9vYmFy"
      );
      return { tag: "ACCEPTED" };
    },
    isTermsOfServiceAccepted: (_) =>
      window.localStorage.getItem("InstallerAcceptedTermsOfService")
        ? { tag: "ACCEPTED" }
        : { tag: "NOT_ACCEPTED" },
  },
});

export const termsOfServiceReducer = termsOfServiceSlice.reducer;
export const termsOfServiceActions = termsOfServiceSlice.actions;

export const selectTermsOfServiceState = (state: RootState) =>
  state.legalInformation;
