import logo from "./icons/logo.png";

export function Navbar() {
  return (
    <>
      <nav className="navbar bg-light">
        <div className="container">
          <a className="navbar-brand" href="/">
            <LogoImage />
          </a>
        </div>
      </nav>
    </>
  );
}

function LogoImage() {
  return (
    <>
      <img
        src={logo}
        style={{ maxWidth: "10rem" }}
        alt="mobil instaladores logo"
      />
    </>
  );
}
