import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authorizationRequest } from "../oauth/oAuthRequests";
import { RootState } from "../store/store";

type AuthenticationState =
  | { tag: "UNAUTHENTICATED" }
  | { tag: "AUTHENTICATED"; accessToken: string };

const initialState: AuthenticationState = {
  tag: "UNAUTHENTICATED",
};

const authenticationSlice = createSlice({
  name: "authentication",
  initialState: initialState as AuthenticationState,
  reducers: {
    login: (_) => authorizationRequest(),
    logout: (_) => ({ tag: "UNAUTHENTICATED" }),
    authenticate: (_, action: PayloadAction<string>) => ({
      tag: "AUTHENTICATED",
      accessToken: action.payload,
    }),
  },
});

export const authenticationReducer = authenticationSlice.reducer;
export const authenticationActions = authenticationSlice.actions;

export const selectAuthenticationState = (state: RootState) =>
  state.authentication;
