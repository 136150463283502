import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { BrandModelsType, brandModelsSchema } from "../../../types/brandModels";
import TruckModelsView from "./TruckModelsView";

export default function TruckModels() {
  const data = useLoaderData();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");

  const brandModels: BrandModelsType = brandModelsSchema.parse(data);
  const filteredModels = brandModels.installations.filter((i) =>
    i.model.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <TruckModelsView
      brand={brandModels.brand}
      installations={filteredModels}
      onClick={() => navigate(-1)}
      onChange={(text) => setSearch(text)}
    />
  );
}
