import {
  TextAlignment,
  textAlignments,
  defaultTextAlignment,
} from "./alignments";
import { Color, colors, defaultColor } from "./colors";

export default function Title({
  color,
  title,
  alignment,
}: {
  color?: Color;
  alignment?: TextAlignment;
  title: string;
}) {
  return (
    <h3
      className={textAlignments[alignment ?? defaultTextAlignment]}
      style={{ color: colors[color ?? defaultColor] }}
    >
      {title}
    </h3>
  );
}
