type Colors = {
  primary: string;
};

export type Color = keyof Colors;

export const colors: Colors = {
  primary: "#1b3059",
};

export const defaultColor: Color = "primary";
