import { useState } from "react";
import BrandModelsList from "../../components/BrandModelsList";
import { Spacer } from "../../design-system/Spacer";
import Text from "../../design-system/Text";
import Title from "../../design-system/Title";
import { VerticalSpacer } from "../../design-system/VerticalSpacer";
import { Installation } from "../../types/installation";
import { VehicleKind } from "../../types/vehicleKinds";

export type VehicleKindHomeView = {
  kind: VehicleKind;
  view: string;
  description: string;
  icon: string;
  modelsCount: number;
  onClick: (value: string) => void;
};

export function HomeView({
  vehicleKindsView,
  installations,
  onSearchChange,
}: {
  vehicleKindsView: VehicleKindHomeView[];
  installations: Installation[];
  onSearchChange: (value: string) => void;
}) {
  return (
    <>
      <Title title="Bem-vindo ao portal Mobil Instalador" alignment="center" />
      <Spacer size="1rem" />

      <Text fontSize="1.3rem">
        Aqui você tem acesso a informações técnicas sobre a instalação de
        rastreadores, de acordo com o tipo de veículo, marca, modelo e muito
        mais.
      </Text>
      <Spacer size="1rem" />

      <Text>Pesquise por um modelo específico de veículo.</Text>
      <input
        type="text"
        className="form-control"
        placeholder="Pesquisar modelo"
        onChange={(text) => onSearchChange(text.target.value)}
      />
      <Spacer size="1rem" />
      <BrandModelsList installations={installations} />

      <Text>
        Em qual tipo de veículo você deseja realizar a instalação do rastreador?
      </Text>
      <Spacer size="1rem" />
      <div className="row">
        {vehicleKindsView.map((kind) => (
          <HomeViewCard vehicleKindView={kind} />
        ))}
      </div>
    </>
  );
}

function HomeViewCard({
  vehicleKindView,
}: {
  vehicleKindView: VehicleKindHomeView;
}) {
  const [isHovered, setIsHovered] = useState(false);
  const iconSize = "5rem";

  return (
    <div className="col-sm-12 col-lg-4">
      <div
        className="card"
        onClick={() => vehicleKindView.onClick(vehicleKindView.kind)}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          cursor: "pointer",
          backgroundColor: isHovered ? "#E9ECEF" : "transparent",
        }}
      >
        <div className="card-body d-flex align-items-center">
          <div>
            <img
              src={vehicleKindView.icon}
              alt={vehicleKindView.view}
              style={{ height: iconSize, width: iconSize }}
            />
          </div>
          <VerticalSpacer size="1rem" />
          <div>
            <Text>{vehicleKindView.view}</Text>
            <Text>{vehicleKindView.description}</Text>
            <Text>{`Quantidade de modelos: ${vehicleKindView.modelsCount}`}</Text>
          </div>
        </div>
      </div>
      <Spacer size="1rem" />
    </div>
  );
}
