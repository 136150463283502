import { redirect } from "react-router-dom";
import { getInstallationsAction } from "../../reducers/installations";
import { termsOfServiceActions } from "../../reducers/legalInformation";
import store from "../../store/store";
import { Installation } from "../../types/installation";

export default async function installationLoader(
  vehicle: string | undefined,
  brand: string | undefined,
  id: string | undefined
): Promise<Installation | Response> {
  if (brand === undefined || id === undefined || vehicle === undefined)
    return redirect("/not-found");

  const installationState = store.getState().installation;

  if (installationState.tag === "UNLOADED") {
    store.dispatch(termsOfServiceActions.isTermsOfServiceAccepted());
    await store.dispatch(getInstallationsAction());
    return await installationLoader(vehicle, brand, id);
  }

  if (installationState.tag === "ERROR") return redirect("/error");

  const installation = installationState.installations.find(
    (i) =>
      i.vehicle === vehicle &&
      i.brand === brand.replaceAll("-", " ") &&
      i.id === id
  );

  if (installation === undefined) return redirect("/not-found");

  return installation;
}
