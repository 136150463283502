type TextAlignments = {
  start: string;
  center: string;
};

export type TextAlignment = keyof TextAlignments;

export const textAlignments: TextAlignments = {
  start: "text-start",
  center: "text-center",
};

export const defaultTextAlignment: TextAlignment = "start";
