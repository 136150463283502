import { redirect } from "react-router-dom";
import { getInstallationsAction } from "../../reducers/installations";
import { termsOfServiceActions } from "../../reducers/legalInformation";
import store from "../../store/store";

export default async function homeLoader() {
  await store.dispatch(getInstallationsAction());
  store.dispatch(termsOfServiceActions.isTermsOfServiceAccepted());

  const installationState = store.getState().installation;

  if (installationState.tag !== "LOADED") return redirect("/error");

  return installationState.installations;
}
