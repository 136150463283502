import Text from "../design-system/Text";

export default function ModelsPageText() {
  return (
    <Text>
      Você está a um passo de obter a informação desejada. Selecione o modelo do
      veículo para obter as informações para a instalação do rastreador
      veicular:
    </Text>
  );
}
