import { RouterProvider } from "react-router-dom";
import { Navbar } from "./design-system/Navbar";
import { Spacer } from "./design-system/Spacer";
import router from "./router/router";

function App() {
  return (
    <>
      <Navbar />
      <Spacer size="1rem" />
      <div className="container">
        <RouterProvider router={router} />
        <Spacer size="1rem" />
      </div>
    </>
  );
}

export default App;
