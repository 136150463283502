import { redirect } from "react-router-dom";
import { authenticationActions } from "../reducers/authentication";
import store from "../store/store";
import { accessTokenRequest } from "./oAuthRequests";

export default async function oAuthCallbackHandler(url: string) {
  const result = await accessTokenRequest(url);

  if (result.ok) {
    store.dispatch(authenticationActions.authenticate(result.value.token));
  }

  return redirect("/");
}
