import { useNavigate } from "react-router-dom";
import { Button } from "../design-system/Button";

export function ErrorPage() {
  const navigate = useNavigate();

  return (
    <>
      <div className="alert alert-danger" role="alert">
        Ocorreu um erro. Tente novamente mais tarde.
      </div>
      <Button label="Voltar" style={`danger`} onClick={() => navigate("/")} />
    </>
  );
}
