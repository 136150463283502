import Text from "../design-system/Text";

export function BrandsPageText({
  vehicleKindView,
}: {
  vehicleKindView: string;
}) {
  return (
    <Text>
      {`Você selecionou a opção "${vehicleKindView}". Escolha a montadora do
  veículo que deseja instalar um rastreador:`}
    </Text>
  );
}
