import { redirect } from "react-router-dom";
import { brandsSchema } from "../../../types/brands";
import store from "../../../store/store";

export default function carBrandsLoader() {
  const installationState = store.getState().installation;

  if (installationState.tag === "UNLOADED") return redirect("/");
  if (installationState.tag === "ERROR") return redirect("/error");

  return brandsSchema.parse(
    Array.from(
      new Set(
        installationState.installations
          .filter((i) => i.vehicle === "car")
          .map((i) => i.brand)
          .sort()
      )
    )
  );
}
