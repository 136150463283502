import Audio from "../../design-system/Audio";
import { Button } from "../../design-system/Button";
import { Spacer } from "../../design-system/Spacer";
import Text from "../../design-system/Text";
import TextPlusElements from "../../design-system/TextPlusElements";
import Title from "../../design-system/Title";
import { Installation } from "../../types/installation";

export default function InstallationView({
  installation,
  onClick,
}: {
  installation: Installation;
  onClick: () => void;
}) {
  return (
    <>
      <Text>
        Todas as informações que precisa estão aqui. Leia e verifique
        atentamente antes de realizar a instalação do rastreador.
      </Text>
      <TextPlusElements>
        <>
          Se tiver dúvidas, entre em{" "}
          <a
            href="https://api.whatsapp.com/send?phone=5551981997265&text=Ol%C3%A1!"
            target="_blank"
            rel="noreferrer"
          >
            contato
          </a>
          .
        </>
      </TextPlusElements>
      <Title title="Informações" />
      <Spacer size="1rem" />
      <Text>{`Modelo: ${installation.model}`}</Text>
      <Text>{`Ano(s): ${installation.modelYears}`}</Text>
      <Text>{`Tipo de fio: ${installation.wire}`}</Text>
      <Text>{`Onde encontrar: ${installation.local}`}</Text>
      {installation.notes && (
        <Text>{`Observações: ${installation.notes}`}</Text>
      )}
      {installation.audio && (
        <Audio url={installation.audio} />
      )}
      <div
        className="container"
        style={{
          maxWidth: "540px",
          padding: 0,
        }}
      >
        {installation.images.map((image) => (
          <>
            <img src={image} alt={installation.id} className="img-fluid" />
            <Spacer size="0.5rem" />
          </>
        ))}
      </div>
      <Spacer size="1.5rem" />
      <Button label="Voltar" style={`primary`} onClick={onClick} />
    </>
  );
}
