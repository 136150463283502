import { createBrowserRouter } from "react-router-dom";
import oAuthCallbackHandler from "../oauth/oAuthCallbackHandler";
import CarBrands from "../pages/car/brands/CarBrands";
import carBrandsLoader from "../pages/car/brands/carBrandsLoader";
import CarModels from "../pages/car/models/CarModels";
import carModelsLoader from "../pages/car/models/carModelsLoader";
import { ErrorPage } from "../pages/ErrorPage";
import Home from "../pages/home/Home";
import homeLoader from "../pages/home/homeLoader";
import Installation from "../pages/installation/Installation";
import installationLoader from "../pages/installation/installationLoader";
import MotorcycleBrands from "../pages/motorcycle/brands/MotorcycleBrands";
import motorcycleBrandsLoader from "../pages/motorcycle/brands/motorcycleBrandsLoader";
import MotorcycleModels from "../pages/motorcycle/models/MotorcycleModels";
import motorcycleModelsLoader from "../pages/motorcycle/models/motorcycleModelsLoader";
import TruckBrands from "../pages/truck/brands/TruckBrands";
import truckBrandsLoader from "../pages/truck/brands/truckBrandsLoader";
import TruckModels from "../pages/truck/models/TruckModels";
import truckModelsLoader from "../pages/truck/models/truckModelsLoader";
import { redirect } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    loader: homeLoader,
  },
  {
    path: "/truck",
    element: <TruckBrands />,
    loader: truckBrandsLoader,
  },
  {
    path: "/truck/:brand",
    element: <TruckModels />,
    loader: ({ params }) => truckModelsLoader(params.brand),
  },
  {
    path: "/:vehicle/:brand/:id",
    element: <Installation />,
    loader: async ({ params }) =>
      await installationLoader(params.vehicle, params.brand, params.id),
  },
  {
    path: "/car",
    element: <CarBrands />,
    loader: carBrandsLoader,
  },
  {
    path: "/car/:brand",
    element: <CarModels />,
    loader: ({ params }) => carModelsLoader(params.brand),
  },
  {
    path: "/motorcycle",
    element: <MotorcycleBrands />,
    loader: motorcycleBrandsLoader,
  },
  {
    path: "/motorcycle/:brand",
    element: <MotorcycleModels />,
    loader: ({ params }) => motorcycleModelsLoader(params.brand),
  },
  {
    path: "/error",
    element: <ErrorPage />,
  },
  {
    // TODO: loading screen
    path: "/cb",
    element: null,
    loader: async ({ request }) => await oAuthCallbackHandler(request.url),
  },
  {
    path: "*",
    element: null,
    loader: () => redirect("/"),
  },
]);

export default router;
