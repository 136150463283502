import { redirect } from "react-router-dom";
import store from "../../../store/store";
import { installationArray } from "../../../types/installation";
import { brandModelsSchema } from "../../../types/brandModels";

export default function motorcycleModelsLoader(brand: string | undefined) {
  const installationState = store.getState().installation;

  if (installationState.tag === "UNLOADED") return redirect("/");
  if (installationState.tag === "ERROR") return redirect("/error");

  if (brand === undefined) return redirect("/not-found");

  const brandModels = installationState.installations.filter(
    (i) => i.vehicle === "motorcycle" && i.brand === brand.replaceAll("-", " ")
  );

  if (brandModels.length === 0) return redirect("/not-found");

  return brandModelsSchema.parse({
    brand: brand.replaceAll("-", " "),
    installations: installationArray
      .parse(brandModels)
      .sort((a, b) => a.model.localeCompare(b.model)),
  });
}
