import { colors, defaultColor, Color } from "./colors";
import { Size } from "./size";

export default function Text({
  color,
  fontSize,
  children,
}: {
  color?: Color;
  fontSize?: Size;
  children: string;
}) {
  return (
    <p
      style={{
        color: colors[color ?? defaultColor],
        fontSize: fontSize ?? "1rem",
      }}
    >
      {children}
    </p>
  );
}
