type Styles =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark";

type ButtonStyle = Styles | `outline-${Styles}`;

export function Button({
  label,
  style,
  disabled,
  onClick,
}: {
  label: string;
  style: ButtonStyle;
  disabled?: boolean;
  onClick: () => void;
}) {
  return (
    <button
      className={`btn btn-${style}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}
