import axios from "axios";
import { z } from "zod";
import { installationSchema } from "../types/installation";
import Result from "./Result";

export const API_BASE_URL = (window as any).API_URL as string;

const installationDTOSchema = installationSchema.extend({
  vehicle: z.string().transform((s) => {
    switch (s) {
      case "Carro e Utilitário":
        return "car";

      case "Caminhão":
        return "truck";

      case "Moto":
        return "motorcycle";

      default:
        return null;
    }
  }),
});

type TrackerInstallationsDTO = z.infer<typeof installationDTOSchema>;

export async function getInstallations(): Promise<
  Result.Result<TrackerInstallationsDTO[], {}>
> {
  try {
    const response = await axios.get<unknown[]>(
      `${API_BASE_URL}/installations`
    );

    const data: TrackerInstallationsDTO[] = response.data
      .map((d: unknown) => installationDTOSchema.parse(d))
      .filter((d) => d.vehicle !== null);

    return Result.ok(data);
  } catch {
    return Result.err({});
  }
}
