import BrandsList from "../../../components/BrandsList";
import { BrandsPageText } from "../../../components/BrandsPageText";
import SearchBar from "../../../components/SearchBar";
import { Button } from "../../../design-system/Button";
import { Spacer } from "../../../design-system/Spacer";
import Title from "../../../design-system/Title";
import { BrandsType } from "../../../types/brands";
import { vehicleKindsView } from "../../../types/vehicleKinds";

export default function TruckBrandsView({
  brands,
  onClick,
  onChange,
}: {
  brands: BrandsType;
  onClick: () => void;
  onChange: (value: string) => void;
}) {
  return (
    <>
      <Title title="Montadoras" />
      <Spacer size="1rem" />
      <BrandsPageText vehicleKindView={vehicleKindsView["truck"]} />
      <Spacer size="1rem" />
      <SearchBar placeholder="Pesquisar montadora" onChange={onChange} />
      <Spacer size="1rem" />
      <BrandsList brands={brands} vehicleKindView={vehicleKindsView["truck"]} />
      <Button label="Voltar" style={`primary`} onClick={onClick} />
    </>
  );
}
