import BrandModelsList from "../../../components/BrandModelsList";
import { Button } from "../../../design-system/Button";
import Title from "../../../design-system/Title";
import { Installation } from "../../../types/installation";
import ModelsPageText from "../../../components/ModelsPageText";
import { Spacer } from "../../../design-system/Spacer";
import SearchBar from "../../../components/SearchBar";

export default function CarModelsView({
  brand,
  installations,
  onClick,
  onChange,
}: {
  brand: string;
  installations: Installation[];
  onClick: () => void;
  onChange: (value: string) => void;
}) {
  return (
    <>
      <Title title={brand} />
      <ModelsPageText />
      <Spacer size="1rem" />
      <SearchBar placeholder="Pesquisar modelo" onChange={onChange} />
      <Spacer size="1rem" />
      <BrandModelsList installations={installations} />
      <Button label="Voltar" style={`primary`} onClick={onClick} />
    </>
  );
}
