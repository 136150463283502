import { Link } from "react-router-dom";
import { Spacer } from "../design-system/Spacer";

export default function BrandsList({
  vehicleKindView,
  brands,
}: {
  vehicleKindView: string;
  brands: string[];
}) {
  return (
    <>
      <div className="row">
        {brands.map((brand) => (
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <Link to={brand.replaceAll(" ", "-")}>
                  <h5 className="card-text text-center">{brand}</h5>
                </Link>
              </div>
            </div>
            <Spacer size="1rem" />
          </div>
        ))}
      </div>
    </>
  );
}
