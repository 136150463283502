import { configureStore } from "@reduxjs/toolkit";
import { authenticationReducer } from "../reducers/authentication";
import { installationReducer } from "../reducers/installations";
import { termsOfServiceReducer } from "../reducers/legalInformation";

const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    installation: installationReducer,
    legalInformation: termsOfServiceReducer,
  },
});

export default store;

// https://react-redux.js.org/tutorials/typescript-quick-start#define-root-state-and-dispatch-types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
