import { Link } from "react-router-dom";
import { Spacer } from "../design-system/Spacer";
import { Installation } from "../types/installation";

export default function BrandModelsList({
  installations,
}: {
  installations: Installation[];
}) {
  return (
    <>
      <div className="row">
        {installations.map((i) => (
          <div className="col-sm-3">
            <div className="card">
              <div className="card-body">
                <Link
                  to={`/${i.vehicle}/${i.brand.replaceAll(" ", "-")}/${i.id}`}
                  relative="route"
                >
                  <h5 className="card-title">{i.model}</h5>
                </Link>
                <p className="card-text text-break">Ano(s): {i.modelYears}</p>
              </div>
            </div>
            <Spacer size="1rem" />
          </div>
        ))}
      </div>
    </>
  );
}
