import { useLoaderData, useNavigate } from "react-router-dom";
import {
  VehicleKind,
  vehicleKinds,
  vehicleKindsView,
} from "../../types/vehicleKinds";
import { HomeView, VehicleKindHomeView } from "./HomeView";
import carIcon from "../../design-system/icons/car_icon.png";
import motorcycleIcon from "../../design-system/icons/motorcycle_icon.png";
import truckIcon from "../../design-system/icons/truck_icon.png";
import { useState } from "react";
import { Installation, installationArray } from "../../types/installation";

export default function Home() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const installations: Installation[] = installationArray.parse(
    useLoaderData()
  );

  const vehicleKindsHomeView = vehicleKinds.map((kind) =>
    mapVehicleKindsToView(
      kind,
      installations.filter((i) => i.vehicle === kind).length,
      (kind) => navigate(kind)
    )
  );

  const filteredInstallations = installations
    .filter(
      (i) =>
        search !== "" && i.model.toLowerCase().includes(search.toLowerCase())
    )
    .sort((a, b) => a.model.localeCompare(b.model));

  return (
    <HomeView
      vehicleKindsView={vehicleKindsHomeView}
      installations={filteredInstallations}
      onSearchChange={(text) => setSearch(text)}
    />
  );
}

function mapVehicleKindsToView(
  vehicleKind: VehicleKind,
  modelsCount: number,
  onClick: (param: string) => void
): VehicleKindHomeView {
  switch (vehicleKind) {
    case "car":
      return {
        kind: vehicleKind,
        view: vehicleKindsView.car,
        description: "Veículos automotores e elétricos de quatro rodas.",
        icon: carIcon,
        onClick: () => onClick(vehicleKind),
        modelsCount,
      };

    case "motorcycle":
      return {
        kind: vehicleKind,
        view: vehicleKindsView.motorcycle,
        description: "Veículos automotores e elétricos de duas ou três rodas.",
        icon: motorcycleIcon,
        onClick: () => onClick(vehicleKind),
        modelsCount,
      };

    case "truck":
      return {
        kind: vehicleKind,
        view: vehicleKindsView.truck,
        description:
          "Veículos automotores e elétricos utilizados em transporte de carga.",
        icon: truckIcon,
        onClick: () => onClick(vehicleKind),
        modelsCount,
      };
  }
}
