import axios from "axios";
import Result from "../api/Result";

const CODE_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? "https://mtk-auth.dev.mobiltk.com.br/authorize"
    : "http://localhost:5020/authorize";

const TOKEN_ENDPOINT =
  process.env.NODE_ENV === "production"
    ? "https://mtk-auth.dev.mobiltk.com.br/token"
    : "http://localhost:5020/token";

const REDIRECT_URI =
  process.env.NODE_ENV === "production"
    ? "https://installer.dev.mobiltk.com.br/cb"
    : "http://localhost:3000/cb";

export function authorizationRequest() {
  const clientId = "0";
  const responseType = "code";

  const authorizationRequest = `${CODE_ENDPOINT}?redirect_uri=${REDIRECT_URI}&client_id=${clientId}&response_type=${responseType}`;

  window.location.replace(authorizationRequest);
}

// request params snake_case as rfc
// https://www.rfc-editor.org/rfc/rfc6749#section-4.1.3
type AccessTokenRequest = {
  grant_type: string;
  code: string;
  redirect_uri: string;
  client_id: string;
};

type AccessTokenResponse = {
  token: string;
  tokenType: string;
  expiresIn: number;
};

export async function accessTokenRequest(
  url: string
): Promise<Result.Result<AccessTokenResponse, {}>> {
  const code = new URL(url).searchParams.get("code");

  if (code === null) return Result.err({});

  const requestData: AccessTokenRequest = {
    code: code,
    redirect_uri: REDIRECT_URI,
    client_id: "0",
    grant_type: "authorization_code",
  };

  try {
    const response = await axios.post<AccessTokenResponse>(
      TOKEN_ENDPOINT,
      requestData,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
    return Result.ok(response.data);
  } catch {
    return Result.err({});
  }
}
