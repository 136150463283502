import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { Button } from "../../design-system/Button";
import { Spacer } from "../../design-system/Spacer";
import Text from "../../design-system/Text";
import {
  termsOfServiceActions,
  selectTermsOfServiceState,
} from "../../reducers/legalInformation";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { installationSchema } from "../../types/installation";
import InstallationView from "./InstallationView";

export default function Installation() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const installationData = useLoaderData();
  const termsOfServiceState = useAppSelector(selectTermsOfServiceState);

  if (termsOfServiceState.tag === "NOT_ACCEPTED") {
    return (
      <LegalInformation
        onConfirm={() => dispatch(termsOfServiceActions.acceptTerms())}
      />
    );
  }

  return (
    <InstallationView
      installation={installationSchema.parse(installationData)}
      // Pass the delta you want to go in the history stack. For example, navigate(-1) is equivalent to hitting the back button.
      // see: https://reactrouter.com/en/main/hooks/use-navigate
      onClick={() => navigate(-1)}
    />
  );
}

export function LegalInformation({ onConfirm }: { onConfirm: () => void }) {
  const [isCheck, setCheck] = useState(false);

  return (
    <>
      <div className="card">
        <h5 className="card-header">Termos de Serviço</h5>
        <div className="card-body">
          <Text>
            Atenção: Não nos responsabilizamos por qualquer tipo de problema em
            instalações de equipamento rastreador veicular. As informações de
            fio de bloqueio podem ser imprecisas e ter variações de acordo com o
            ano e modelo do veículo. Sempre utilize o multímetro para testar os
            fios antes de realizar qualquer corte. É necessário conhecimentos
            básico de elétrica automotiva para utilizar as informações aqui
            fornecidas.
          </Text>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              onClick={() => setCheck(!isCheck)}
            />
            <Text>Concordo com os termos de serviço do Mobil Instalador</Text>
          </div>
          <Spacer size="1rem" />
          <Button
            label="Continuar"
            style={`primary`}
            onClick={onConfirm}
            disabled={!isCheck}
          />
        </div>
      </div>
    </>
  );
}
