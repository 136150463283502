export default function SearchBar({
  placeholder,
  onChange,
}: {
  placeholder: string;
  onChange: (value: string) => void;
}) {
  return (
    <input
      type="text"
      className="form-control"
      placeholder={placeholder}
      onChange={(text) => onChange(text.target.value)}
    />
  );
}
