import { z } from "zod";
import { vehicleKindSchema } from "./vehicleKinds";

export const installationSchema = z.object({
  id: z.string(),
  datetime: z.string(),
  vehicle: vehicleKindSchema,
  brand: z.string(),
  model: z.string(),
  modelYears: z.string(),
  wire: z.string(),
  local: z.string(),
  notes: z.string(),
  images: z.string().array(),
  audio: z.string(),
});

export type Installation = z.infer<typeof installationSchema>;

export const installationArray = z.array(installationSchema);
