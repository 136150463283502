import { z } from "zod";
import { Concrete } from "./concrete";

export const vehicleKinds = ["car", "motorcycle", "truck"] as const;
export type VehicleKinds = typeof vehicleKinds;

export const vehicleKindSchema = z.enum(vehicleKinds);
export type VehicleKind = z.infer<typeof vehicleKindSchema>;

const vehicleKindsViewSchema = z.record(vehicleKindSchema, z.string());
type VehicleKindsViewTypePartial = z.infer<typeof vehicleKindsViewSchema>;

export type VehicleKindsViewType = Concrete<VehicleKindsViewTypePartial>;
export const vehicleKindsView: VehicleKindsViewType = {
  car: "Carros e Utilitários",
  motorcycle: "Motos",
  truck: "Caminhões",
};
